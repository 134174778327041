<template>
  <transition name="fade">
    <join v-if="isSignupOpen" @close="onCloseSignup"/>
  </transition>

  <transition name="fade">
    <join-complete v-if="isSignupCompleteOpen" @close="isSignupCompleteOpen = false;isLoginOpen = true"/>
  </transition>

  <div class="loginWrap">
    <div class="inner">
      <div class="loginLogo"><img src="@/assets/img/login_logo.png" /></div>
      <div class="loginInputWrap">
        <div>
          <!-- <h2>User Login</h2> -->
          <ui-input :className="'logininput id'" :type="'text'" :placeholder="$t('front.common.memId')" @input="getMemId" style="margin-top:0"/>
          <ui-input :className="'logininput pw'" :type="'password'" :placeholder="$t('front.common.password')" @input="getMemPass" @keyup.enter="onSubmit"/>
          <section class="captcha-box">
            <VueClientRecaptcha
                :value="inputValue"
                :count="4"
                chars="12345"
                :hideLines="true"
                custom-text-color="black"
                @getCode="getCaptchaCode"
                @isValid="checkValidCaptcha"
            >
              <template>
                <span style="color: blue">with Custom Text Or Icon</span>
              </template>
            </VueClientRecaptcha>
            <input
                v-model="inputValue"
                placeholder="자동입력방지코드"
                class="input"
                type="text"
                @keyup.enter="onSubmit"
            />
          </section>
          <ui-button :className="'loginnbtn login'" @click.prevent="onSubmit" />
          <ui-button :className="'loginnbtn join'" @click="onClickSignup"/>
        </div>
      </div>
      <div class="loginsnsWrap">
        <a><img src="../assets/img/tele.gif"></a>
      </div>
      <p class="logintxt">Copyright 2024 GOLDMEMBERS. All Rights Reserved</p>
    </div>
  </div>
</template>

<script>

import UiButton from '@/components/ui/UiButton'
import UiInput from '@/components/ui/UiInput'

import Join from '@/components/member/Join'
import JoinComplete from '@/components/member/JoinComplete'
import { signIn } from '@/api/member'
import { setAuthData } from '@/libs/auth-helper'
import store from '@/store'

import VueClientRecaptcha from 'vue-client-recaptcha'
import { mapState } from 'vuex'

export default {
  name: 'Signin',
  components: {
    JoinComplete,
    Join,
    UiButton,
    UiInput,
    VueClientRecaptcha
  },
  computed: {
    ...mapState([
      'userData'
    ]),
    canSubmit () {
      return this.model.memId && this.model.memPass
    }
  },
  data () {
    return {
      isSignupOpen: false,
      isSignupCompleteOpen: false,
      model: {
        memId: '',
        memPass: ''
      },
      data: {
        captchaCode: null,
        isValid: false
      },
      inputValue: null
    }
  },
  methods: {
    getCaptchaCode (value) {
      this.data.captchaCode = value
    },
    checkValidCaptcha (value) {
      this.data.isValid = value
    },
    getMemId (value) {
      this.model.memId = value
    },
    getMemPass (value) {
      this.model.memPass = value
    },
    onClickSignup () {
      this.isLoginOpen = false
      this.isSignupOpen = true
    },
    onCloseSignup (status) {
      this.isSignupOpen = false
      if (status) {
        this.isSignupCompleteOpen = true
      }
    },
    onSubmit () {
      if (!this.data.isValid) {
        this.onAlert('warningart', '자동 입력 방지 코드를 확인해주세요.')
        return false
      }

      if (this.model.memId === '') {
        this.onAlert('warningart', 'front.signin.emptyMemId')
        return
      }
      if (this.model.memPass === '') {
        this.onAlert('warningart', 'front.signin.emptyMemPass')
        return
      }

      if (!this.canSubmit) {
        return
      }

      signIn(this.model).then(async response => {
        const result = response.data
        const data = result.data
        if (result.resultCode === '0') {
          setAuthData(data)
          store.commit('setUserData', data.member)
          store.dispatch('storeCommonCode').then(commonCode => {
            this.replacePageByName('main')
          })
        } else {
          await this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        }
      }).catch(err => {
        console.error('login err : ', err.message)
        if (err.message === 'no member') {
          // alert('로그인정보가 올바르지 않습니다. 확인해주세요.')
          this.onCheck('로그인정보가 올바르지 않습니다. 확인해주세요.')
        } else {
          alert(err.message)
        }
      })
    }
  },
  created () {
    console.log(this.userData)
    if (this.userData) {
      this.replacePageByName('main')
    }
  }
}
</script>
<style scoped>
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/loginNew.css"></style>
